@media print {
    @page {
        size: auto;
    }

    .ex-grid-col-container {
        max-width: var(--print-width);
        vertical-align: top;
    }

    ex-card-multi-advanced {
        page-break-inside: avoid;
    }

    app-toolbar,
    navigation,
    ex-navigation-menu *,
    md-tooltip,
    .ex-mono-occurrence-ecran-container-bouton-action-wrapper,
    ex-bandeau-page,
    ex-panneau-page,
    md-card-actions.ex-card-actions,
    ex-pieces-jointes,
    ex-input-validation,
    md-errors-spacer,
    ex-plaquettes-fonctions-transversales,
    .ex-grid-col-total,
    .ex-grid-col-bouton,
    md-icon,
    .ex-grid-col-action-container,
    .ex-grid-col-bouton-deplacement-fixe,
    .md-char-counter,
    label:after,
    .ex-grid-no-data-cercle,
    ex-pagination,
    md-progress-circular,
    ex-card-cycle {
        display: none !important;
    }

    md-card-content {
        border: none;
        border-bottom-width: 1pt;
    }

    .md-whiteframe-z2 {
        box-shadow: none;
    }

    body {
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        pointer-events: none;
        font-size: 10pt;

        * {
            flex: none !important;
            pointer-events: none !important;
            font-family: Roboto, Arial, sans-serif !important;
        }

        md-card-title-text,
        .titre-imprimable {
            font-size: 12pt;
            font-weight: bold;
        }

        ex-data-value[col="titre"] {
            font-size: 16pt;
            font-weight: bold;
            text-align: right;
        }

        .titre-imprimable,
        ex-data-value[col="titre"] {
            margin-bottom: 2mm;
        }

        md-input-container {
            label {
                position: relative !important;
                bottom: auto !important;
                display: inline-block;
                width: 5cm;
                padding: 2mm 0 !important;
                height: 100%;
                vertical-align: top;
            }

            input {
                display: inline-block;
                width: 20cm !important;
                word-wrap: break-word;
                border: none;
                padding: 2mm 0 !important;
            }

            textarea {
                display: none !important;
            }

            .ex-input-description-print {
                display: inline-block !important;
                width: 20cm !important;
                height: auto !important;
                border: none;
                resize: none;
                padding: 2mm 0 !important;
            }
        }

        .ex-input-date {
            width: 100% !important;
        }

        .ex-formulaire-input-list-champ-container>div,
        .ex-formulaire-input-list-champ-container>div * {
            padding-left: 0 !important;
        }

        ex-card-multi-template {
            display: block;
            margin-top: 1cm;
            background-color: white !important;
        }

        ex-card-multi-advanced {
            padding-top: .75cm;
            margin-bottom: 5mm !important;
        }

        ex-card-formulaire-maitre,
        ex-card-multi-advanced,
        ex-card-infos-monetaires {
            display: table;
            background-color: white !important;
            border-top-color: gray;
            border-top-style: solid;
            border-top-width: .01mm;
            width: 100%;
            margin-bottom: .1mm;
            margin-top: 3mm;
        }

        .ex-grid-head-col-wrapper>div,
        .ex-grid-col-wrapper>div,
        .ex-grid-col-container,
        .ex-grid-no-data>div {
            display: inline-block;
            padding: 2mm;
        }

        .ex-grid-row-totaux * {
            display: inline-block;
            font-weight: bold;
            text-align: right;
        }

        .ex-primary-hue-2-fg,
        .ex-primary-hue-3-fg {
            color: black !important;
            background-color: white;
        }

        .ex-grid-row {
            border-width: 0 .05mm .05mm .05mm !important;
            border-color: lightgrey;
            border-style: solid;
        }

        .ex-grid-head-col-wrapper {
            border-width: 0 0 .75mm 0 !important;
            border-color: black;
            border-style: solid;
            font-weight: bold;
        }

        button {
            background: none;
            color: inherit;
            border: none;
            padding: 0 !important;
            font: inherit;
            cursor: pointer;
        }

        .ex-card-content-container {
            padding-top: .5cm;
        }

        .ex-multi-occurrence-template-body {
            display: table;
        }

        ex-input-string {
            width: 100%;

            input {
                border-bottom: 0;
                padding-left: 0;
                color: black;
            }

            label {
                color: rgba(0, 0, 0, 0.54);
            }
        }

        ex-card-infos-monetaires {
            .ex-data-value {
                font-size: 15pt;
                font-weight: bold;
                margin-bottom: 2mm;
            }

            div.layout-row:not(:first-child) {
                margin-top: 7.5mm;
            }

            .ex-data-label {
                font-size: 12pt;
                font-weight: bold;
                margin-bottom: 5mm;
            }
        }

        ex-button-tri>button,
        ex-button-tri>button>span {
            width: 100%;
        }

        ex-grid>div {
            min-width: 1000px;//IMPORTANT Pour contrer le ajuster à la page
        }
    }
}

@import './pages/cp/cp2000/cp2000';
