@media print {
    .cp2000-infos-monetaires {
        width: 100%;
        border-bottom-color: gray;
        border-bottom-style: solid;
        border-bottom-width: .01mm;
        padding-bottom: 3mm;
        page-break-inside: avoid;
    }
}

@media screen {
    .cp2000-infos-monetaires>span {
        display: none;
    }
}
